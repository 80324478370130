import { SEND_TEXT_MESSAGE_FAILURE, SEND_TEXT_MESSAGE_REQUEST } from '../../domain/Conversation/constants';

export const AnalyticKeys = {
    SERVICE_DROPDOWN_SEARCH_MODAL: 'SERVICE_DROPDOWN_SEARCH_MODAL',
    SERVICE_DROPDOWN_SEARCH_MODAL_SELECTED: 'SERVICE_DROPDOWN_SEARCH_MODAL_SELECTED',
    DATE_PICKER_SEARCH_MODAL: 'DATE_PICKER_SEARCH_MODAL',
    PET_DROPDOWN_SEARCH_MODAL: 'PET_DROPDOWN_SEARCH_MODAL',
    SEARCH_NOW_SEARCH_MODAL: 'SEARCH_NOW_SEARCH_MODAL',
    SERVICE_DROPDOWN_SEARCH_HOMEPAGE: 'SERVICE_DROPDOWN_SEARCH_HOMEPAGE',
    SERVICE_DROPDOWN_SEARCH_HOMEPAGE_SELECTED: 'SERVICE_DROPDOWN_SEARCH_HOMEPAGE_SELECTED',
    DATE_PICKER_SEARCH_HOMEPAGE: 'DATE_PICKER_SEARCH_HOMEPAGE',
    SEARCH_NOW_SEARCH_HOMEPAGE: 'SEARCH_NOW_SEARCH_HOMEPAGE',
    SITTER_PROFILE_CONTACT: 'SITTER_PROFILE_CONTACT',
    DASHBOARD_SEARCH_BUTTON: 'DASHBOARD_SEARCH_BUTTON',
    SEARCH_SITTERS_HEADER: 'SEARCH_SITTERS_HEADER',
    SEARCH_SITTERS_HEADER_ANONYMOUS: 'SEARCH_SITTERS_HEADER_ANONYMOUS',
    AVAILABLE_TO_BOOK: 'AVAILABLE_TO_BOOK',
    WAIT_FOR_A_REPLY_POPUP_PRESENTED: 'WAIT_FOR_A_REPLY_POPUP_PRESENTED',
    WAIT_FOR_A_REPLY_POPUP_OK: 'WAIT_FOR_A_REPLY_POPUP_OK',
    BOOKING_REQUESTED_BOOK_NOW: 'BOOKING_REQUESTED_BOOK_NOW',
    MEET_AND_GREET_POPUP_PRESENTED: 'MEET_AND_GREET_POPUP_PRESENTED',
    MEET_AND_GREET_POPUP_CLOSED: 'MEET_AND_GREET_POPUP_CLOSED',
    MEET_AND_GREET_POPUP_BOOK_NOW: 'MEET_AND_GREET_POPUP_BOOK_NOW',
    MEET_AND_GREET_POPUP_CANCEL: 'MEET_AND_GREET_POPUP_CANCEL',
    BOOKING_REQUESTED_PAYMENT: 'BOOKING_REQUESTED_PAYMENT',
    BOOKING_REQUEST_NEXT: 'BOOKING_REQUEST_NEXT',
    BOOKING_REQUEST_SEND: 'BOOKING_REQUEST_SEND',
    SEND_TEXT_MESSAGE_REQUEST: SEND_TEXT_MESSAGE_REQUEST + '_FAILED',
    SEND_TEXT_MESSAGE_FAILURE: SEND_TEXT_MESSAGE_FAILURE,
};
