import download from 'downloadjs';
import { getInstance } from './PawshakeWorldRegionApi';
import { getAuthorizationHeader } from './AuthorizationHeader';
import { CoreApi } from '../../api/CoreApi';

export class BaseApi extends CoreApi {
    returnAsByteStream = (url, jwtToken, fileName) => {
        const config = getAuthorizationHeader(jwtToken);
        const worldRegion = this.getWorldRegionBasedUponJwtToken(jwtToken);

        return getInstance(worldRegion)
            .get(url, Object.assign(config, { responseType: 'blob' }))
            .then((response) => {
                const contentType = response.headers['content-type'];
                const fileAsBlob = new Blob([response.data], {
                    type: contentType,
                });
                download(fileAsBlob, fileName, contentType);
                return { status: 200 };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    };
}
