import createRequestActionStrings from '../../utils/createRequestActionStrings';

export const [CONVERSATION_MESSAGES_REQUEST, CONVERSATION_MESSAGES_SUCCESS, CONVERSATION_MESSAGES_FAILURE] =
    createRequestActionStrings('CONVERSATION_MESSAGES');

export const CONVERSATION_FIRST_PAGE_MESSAGES_LOADED = 'CONVERSATION_FIRST_PAGE_MESSAGES_LOADED';

export const RESET_CONVERSATION_AND_BOOKING_STORES = 'RESET_CONVERSATION_AND_BOOKING_STORES';

export const [CONVERSATION_PARTICIPANTS_REQUEST, CONVERSATION_PARTICIPANTS_SUCCESS, CONVERSATION_PARTICIPANTS_FAILURE] =
    createRequestActionStrings('CONVERSATION_PARTICIPANTS');

export const [
    CONVERSATION_ALLOWED_INTERACTIONS_REQUEST,
    CONVERSATION_ALLOWED_INTERACTIONS_SUCCESS,
    CONVERSATION_ALLOWED_INTERACTIONS_FAILURE,
] = createRequestActionStrings('CONVERSATION_ALLOWED_INTERACTIONS');

export const [SEND_TEXT_MESSAGE_REQUEST, SEND_TEXT_MESSAGE_SUCCESS, SEND_TEXT_MESSAGE_FAILURE] =
    createRequestActionStrings('SEND_TEXT_MESSAGE');

export const [SEND_IMAGE_REQUEST, SEND_IMAGE_SUCCESS, SEND_IMAGE_FAILURE] = createRequestActionStrings('SEND_IMAGE');

export const [ARCHIVE_CONVERSATION_REQUEST, ARCHIVE_CONVERSATION_SUCCESS, ARCHIVE_CONVERSATION_FAILURE] =
    createRequestActionStrings('ARCHIVE_CONVERSATION');

export const [UNARCHIVE_CONVERSATION_REQUEST, UNARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_FAILURE] =
    createRequestActionStrings('UNARCHIVE_CONVERSATION');
